import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Tooltip } from "@mui/material"
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import React, { useEffect, useState } from "react";
import { Geometry } from "geojson";

export type TAddFeatureTabFormStoreValues = {
    geometry: any
    properties: { name: string, value: unknown }[]
    changedProperties?: { [key: string]: any }
    propertyToFocus?: string
}
type AddFeatureTabProps = {
    proposalInfo: any | null
    isAddingFeature: boolean,
    saveFeature: (feature: any) => Promise<void>
    cancelAddFeature: () => void
    focusFeatureOnMap: (geometry: Geometry, zoom?: number) => void
}

export const AddFeatureTab: React.FC<AddFeatureTabProps> = ({
    proposalInfo,
    isAddingFeature,
    saveFeature,
    cancelAddFeature,
    focusFeatureOnMap
}) => {
    const [saving, setIsSaving] = useState(false)
    const [formValuesChanged, setFormValuesChanged] = useState<string[]>([])
    const [formValues, setFormValues] = useState<TAddFeatureTabFormStoreValues>({ geometry: null, properties: [], changedProperties: [] })

    const isAChangedProperty = (changedProperties: {
        [key: string]: any;
    } | undefined, propName: string) => {
        if (!changedProperties) {
            return false
        }
        return typeof changedProperties[propName] !== 'undefined'
    }

    useEffect(() => {
        if (proposalInfo) {
            setFormValues({
                geometry: proposalInfo.geometry,
                properties: proposalInfo.properties ?? [],
                changedProperties: proposalInfo.changedProperties
            })
        }
    }, [proposalInfo])

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propertyIndex: number) => {
        let property = formValues.properties[propertyIndex]
        setFormValuesChanged([property.name, ...formValuesChanged])
        formValues.properties[propertyIndex].value = event.target.value
        setFormValues(formValues)
    }

    const onSave = async () => {
        setFormValuesChanged([])
        setIsSaving(true)
        const feature = {
            type: "Feature",
            geometry: formValues.geometry,
            properties: formValues.properties.reduce((a: any, v: any) => ({ ...a, [v.name]: v.value }), {})
        }
        await saveFeature(feature);
        setIsSaving(false)
    }

    const cancel = () => {
        setFormValuesChanged([])
        cancelAddFeature()
    }

    return (
        <React.Fragment>
            <>           {isAddingFeature &&
                <Grid container direction={'row'} alignItems={'normal'}>
                    <Grid item xs={4}>
                        <Tooltip title="Cancel">
                            <Button type="reset" disabled={saving} onClick={() => cancel()}><CancelIcon /></Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={4} >
                        <Tooltip title="Focus">
                            <Button disabled={(!formValues || !formValues.geometry)}
                                onClick={() => focusFeatureOnMap(formValues.geometry, 18)} ><LocationSearchingIcon /></Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                        <Tooltip title="Save">
                            <Button type="submit" onClick={() => onSave()} disabled={saving}><SaveIcon /></Button>
                        </Tooltip>
                    </Grid>
                </Grid>
            }
                <TableContainer >
                    <Table size={'small'}>
                        <TableBody>
                            {formValues.properties?.map((_, i) => {
                                let conditionalStyles = null
                                if (formValues.changedProperties) {
                                    conditionalStyles = isAChangedProperty(formValues.changedProperties, formValues.properties[i].name) ? { backgroundColor: 'rgb(202, 210, 255)', opacity: .8 } : undefined
                                }
                                if (formValuesChanged.includes(formValues.properties[i].name)) {
                                    conditionalStyles = { ...conditionalStyles, backgroundColor: 'rgb(202, 210, 255)', opacity: .8 }
                                }

                                return (
                                    <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 }, ...conditionalStyles }}>
                                        <TableCell align="right">
                                            <TextField label={formValues.properties[i].name}
                                                InputLabelProps={{
                                                    shrink: true,  // Forces the label to stay above the input field
                                                }}
                                                variant="outlined"
                                                size="small"
                                                margin="normal"
                                                sx={{ width: '100%' }} onChange={(event) => handleOnChange(event, i)} value={formValues.properties[i].value} />
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        </React.Fragment>
    )
}
