import { Box, Button, Checkbox, Divider, List, ListItem, ListItemText, Popover, Typography } from "@mui/material"
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { TLayer } from "../../../hooks/useOrganization"
import React, { Fragment, useState } from "react"
import { RGBColor, SketchPicker } from "react-color";
import { DragDropContext, Draggable, Droppable, DropResult } from "@hello-pangea/dnd";


type SelectLayerTabProps =
    {
        layerSelection: TLayerSelection[],
        orgLayers: TLayer[],
        updateSelectedLayer: (layer: TLayerSelection) => void
    }

export class TLayerSelection {

    constructor(layerInfo: TLayer, checked: boolean, rgbColor: RGBColor | null | undefined, selectionOrder: number) {
        this.layerInfo = layerInfo;
        this.checked = checked;
        this.selectionOrder = selectionOrder;

        // Provide default rgbColor if not provided
        if (!rgbColor) {
            this.rgbColor = { r: 0, g: 0, b: 255 };
        } else {
            this.rgbColor = rgbColor;
        }
    }

    selectionOrder: number;
    layerInfo: TLayer;
    checked: boolean;
    rgbColor: RGBColor;

    rgb1(): string {
        return `rgba(${this.rgbColor.r}, ${this.rgbColor.g}, ${this.rgbColor.b}, 1)`
    }

    rgb0(): string {
        return `rgba(${this.rgbColor.r}, ${this.rgbColor.g}, ${this.rgbColor.b}, 0)`
    }
}

export const SelectLayerTab: React.FC<SelectLayerTabProps> = ({ layerSelection, updateSelectedLayer }) => {
    const orderedLayerSelection = layerSelection.sort((a, b) => a.selectionOrder - b.selectionOrder)


    const handleToggle = (layerIndex: number) => {
        orderedLayerSelection[layerIndex].checked = !orderedLayerSelection[layerIndex].checked
        updateSelectedLayer(orderedLayerSelection[layerIndex])
    }

    const handleOnDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        const updatedLayers = Array.from(orderedLayerSelection);
        const [movedLayer] = updatedLayers.splice(result.source.index, 1);
        updatedLayers.splice(result.destination.index, 0, movedLayer);

        for (let i = 0; i < updatedLayers.length; i++) {
            const prev = updatedLayers[i];
            updateSelectedLayer(new TLayerSelection(prev.layerInfo, prev.checked, prev.rgbColor, i))
        }

    };

    return (
        <React.Fragment>
            <Typography variant="h6">Select Layer</Typography>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="layer-selection">
                    {(provided) => (
                        <List {...provided.droppableProps} ref={provided.innerRef}>
                            {orderedLayerSelection.map((layer, layerIndex) => (
                                <Draggable key={layer.layerInfo.name} draggableId={layer.layerInfo.name} index={layerIndex}>
                                    {(provided) => (
                                        <Fragment>
                                            <ListItem
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <Checkbox
                                                    onClick={() => handleToggle(layerIndex)}
                                                    edge="start"
                                                    checked={layer.checked}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                                <ListItemText primary={layer.layerInfo.name} />
                                                {layer.checked ? <ColorPicker updateSelectedLayer={updateSelectedLayer} layer={layer} /> : ""}

                                                <DragIndicatorIcon sx={{ marginLeft: "5px" }} />
                                            </ListItem>
                                            <Divider />
                                        </Fragment>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>

        </React.Fragment>
    )
}


type ColorPickerProps =
    {
        layer: TLayerSelection,
        updateSelectedLayer: (layer: TLayerSelection) => void
    }

const ColorPicker: React.FC<ColorPickerProps> = (props) => {
    const [anchorEl, setAnchorEl] = useState(null); // For Popover positioning
    const [open, setOpen] = useState(false); // For controlling Popover visibility

    // Handle click to open the color picker
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    // Handle closing of the color picker
    const handleClose = () => {
        setOpen(false);
    };

    // Handle color change and close the picker
    const handleChangeComplete = (color: any) => {
        props.layer.rgbColor = color.rgb
        props.updateSelectedLayer(
            props.layer
        )
    };

    // Get RGB values from the color

    return (
        <Box onClick={e => { e.stopPropagation() }}>
            <Button
                variant="contained"
                onClick={handleClick}
                sx={{ backgroundColor: props.layer.rgb1(), padding: '10px 10px', minWidth: '20px' }}
            >
            </Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <SketchPicker
                    color={props.layer.rgbColor}
                    onChangeComplete={handleChangeComplete}
                />
            </Popover>
        </Box>
    );
};
