import { useMutation } from 'react-query'

import { TFileUpload, TOrganization } from '../hooks/useOrganization'
import UploadFileGDB from './UploadFileGDB'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    TextField,
    Tooltip,
    Typography,
    CircularProgress
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FactCheckIcon from '@mui/icons-material/FactCheck';
import MapIcon from '@mui/icons-material/Map'
import TuneIcon from '@mui/icons-material/Tune'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import TableChartIcon from '@mui/icons-material/TableChart';
import { Link } from 'react-router-dom'
import LinearProgress from '@mui/material/LinearProgress'
import { red, green, blue, orange } from '@mui/material/colors'
import { startValidations } from '../api/validationsController'
import { deleteOrgFileUpload, createOrgFileDownload, nameFileUpload } from '../api/fileUploadController'
import { useState } from 'react'

const dtStrOpts: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
}

function fileUploadStatusColor(status: string) {
    switch (status) {
        case 'imported':
            return green[500]
        case 'failed':
            return red[500]
        case 'partial_import':
            return orange[500]
        default:
            return blue[500]
    }
}

function fileUploadStatusDisplayName(status: string) {
    // uppercase first letter
    const statusDisplayName = status.charAt(0).toUpperCase() + status.slice(1)
    return statusDisplayName.replaceAll('_', ' ')
}

function layerUploadStatusColor(status: string) {
    switch (status) {
        case 'imported':
            return green[500]
        case 'partial_import':
            return orange[500]
        case 'failed':
            return red[500]
        default:
            return blue[500]
    }
}

function layerUploadStatusDisplayName(status: string) {
    // uppercase first letter
    const statusDisplayName = status.charAt(0).toUpperCase() + status.slice(1)
    return statusDisplayName
}

function validationStatusColor(status: string) {
    switch (status) {
        case 'validated':
            return green[500]
        case 'failed':
            return red[500]
        default:
            return blue[500]
    }
}

function validationStatusDisplayName(status: string) {
    // uppercase first letter
    const statusDisplayName = status.charAt(0).toUpperCase() + status.slice(1)
    return statusDisplayName
}


const OrganizationFileUploads: React.FC<{ accessToken: string, organizationResult: TOrganization, fetchOrganization: (num: number) => void }> = ({ accessToken, organizationResult, fetchOrganization }) => {

    const { mutate: updateFileUploadName, isLoading: isUpdatingFileUploadName } = useMutation(
        'update-upload-name',
        async ({ organizationId, uploadId, name }: { organizationId: string; uploadId: string; name: string }) => {
            return nameFileUpload(accessToken, organizationId, uploadId, name)
        },
        {
            onSuccess: () => {
                closeEditFileUploadNameDialog()
            },
            onError: (error, vars, ctx) => {
                alert(`Unexpected error updating upload name`)
                console.error('OOPS\n', error, vars, ctx)
            },
        },
    )

    const { mutate: mutStartValidations, isLoading: isStartingValidations } = useMutation('validations-start', async ({ organizationId, uploadId }: { organizationId: string; uploadId: string }) => {
        return startValidations(accessToken, organizationId, uploadId)
    }, {
        onSuccess: ({ data: responseBody }) => {
            console.log('downloadGDBMutate.onSuccess', JSON.stringify(responseBody, null, 2))
        }
    });

    const { mutate: downloadGDBMutate, isLoading: isDownloading } = useMutation('download-org-file-upload', async ({ organizationId, uploadId }: { organizationId: string; uploadId: string }) => {
        return createOrgFileDownload(accessToken, organizationId, uploadId)
    }, {
        onSuccess: ({ data: responseBody }) => {
            console.log('downloadGDBMutate.onSuccess', JSON.stringify(responseBody, null, 2))
        }
    });

    const { mutate: deleteOrgFileUploadMutate, isLoading: isDeleting } = useMutation(
        'delete-org-file-upload',
        async ({ organizationId, uploadId }: { organizationId: string; uploadId: string }) => {
            return deleteOrgFileUpload(accessToken, organizationId, uploadId)
        },
        {
            onSuccess: () => {
                closeDeleteDialog()
                setShowDeleteSuccess(true)
                fetchOrganization(300)
            },
            onError: (error, vars, ctx) => {
                alert(`Unexpected error deleting upload`)
                console.error('OOPS\n', error, vars, ctx)
            },
        },
    )

    const [showDeleteSuccess, setShowDeleteSuccess] = useState(false)
    const handleDeleteSuccessSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        setShowDeleteSuccess(false)
    }

    type TExpansionMap = {
        [uploadId: string]: boolean
    }
    const [accordionExpansionMap, setAccordionExpansionMap] = useState<TExpansionMap>({})

    type TEditFileUploadNameContext = {
        organizationId: string
        uploadId: string
        friendlyName: string
        setFriendlyName: (ctx: TEditFileUploadNameContext, name: string) => void
    }
    const [editFileUploadNameContext, setEditFileUploadNameContext] = useState<TEditFileUploadNameContext | null>(null)
    const [openEditFileUploadNameDialog, setOpenEditFileUploadNameDialog] = useState(false)
    const closeEditFileUploadNameDialog = () => {
        setEditFileUploadNameContext(null)
        setOpenEditFileUploadNameDialog(false)
    }

    const [deleteContext, setDeleteContext] = useState<{ organizationId: string; uploadId: string; fileName: string } | null>(null)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const closeDeleteDialog = () => {
        setDeleteContext(null)
        setOpenDeleteDialog(false)
    }


    const organization = organizationResult
    type TOFUFileUpload = TFileUpload & {
        expandAccordion: boolean
        uploadNumber: number
    }

    function allFilesFinished(fUpload: TOFUFileUpload) {
        for (let layer of fUpload.layers) {
            if (layer.status !== 'failed' && layer.status !== 'imported') {
                return true
            }
        }
        return false
    }


    let currUploadNumber = organization.fileUploads.length
    const descFileUploads: TOFUFileUpload[] = organization.fileUploads
        .sort((a, b) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        })
        .map((fileUpload, idx) => {
            let expandAccordion = false

            if (idx === 0) {
                const firstUpload = fileUpload
                const firstUploadCreatedAt = new Date(firstUpload.createdAt)
                const now = new Date()
                const diff = now.getTime() - firstUploadCreatedAt.getTime()
                const diffInMinutes = Math.round(diff / 60000)
                expandAccordion = diffInMinutes < 60
            }

            const tofu: TOFUFileUpload = {
                ...fileUpload,
                uploadNumber: currUploadNumber--,
                expandAccordion: expandAccordion,
            }
            return tofu
        })

    // calculate overall upload progress from all layers
    let uploadProgress = 0
    let totalExpectedFeatureCount = 0
    let totalInsertedFeatureCount = 0
    for (let fUpload of descFileUploads) {
        for (let layer of fUpload.layers) {
            if (!layer) {
                continue
            }
            totalExpectedFeatureCount += layer.expectedFeatureCount
            totalInsertedFeatureCount += layer.insertedFeatureCount ?? 0
        }
    }
    uploadProgress =
        totalInsertedFeatureCount === totalExpectedFeatureCount
            ? 100
            : totalExpectedFeatureCount
                ? (totalInsertedFeatureCount / totalExpectedFeatureCount) * 100
                : 0

    // this is used to determine if the downloadUrl is less than a day old because that's the current expiry set on the downloadUrl
    function isLessThanADayOld(processedAt: string | undefined) {
        if (!processedAt) {
            return false;
        }
        const now = +new Date();
        const processedAtUTCMS = +new Date(processedAt);

        return (now - processedAtUTCMS) < 86400000; // 24 hours in milliseconds
    }

    return (
        <>
            <UploadFileGDB
                organizationId={organization.id}
                accessToken={accessToken}
                onUploadComplete={() => {
                    fetchOrganization(600)
                }}
            />

            <Divider style={{ margin: '32px 0' }} />

            {descFileUploads.length === 0 && (
                <>
                    <Typography variant="h6">No file uploads</Typography>
                </>
            )}
            {descFileUploads.map((fUpload) => {
                const expandAccordion =
                    typeof accordionExpansionMap[fUpload.uploadId] === 'boolean' ? accordionExpansionMap[fUpload.uploadId] : fUpload.expandAccordion
                const disableLinkStyles: React.CSSProperties = {
                    pointerEvents: fUpload.status === 'imported' ? 'auto' : 'none',
                    opacity: fUpload.status === 'imported' ? 1 : 0.5,
                }

                // import datetimes
                const importStartDTStr = new Date(fUpload.createdAt).toLocaleDateString('en-us', dtStrOpts)
                const importFinishDTStr = typeof fUpload.processedAt === 'string' && !fUpload.processedAt.startsWith('0001')
                    ? new Date(fUpload.processedAt).toLocaleDateString('en-us', dtStrOpts) : null;


                // downloads
                const orgDownloads = organizationResult.fileDownloads || [];
                const orgUploadDownloads = orgDownloads.filter((fd) => fd.uploadId === fUpload.uploadId);
                const latestMatchingDownload = orgUploadDownloads.length > 0 ? orgUploadDownloads[orgUploadDownloads.length - 1] : null;
                const isDownloadingLatestMatchingDownload = ['queued', 'generating'].indexOf(latestMatchingDownload?.status || '') > -1;
                // todo: add validation for downloadUrl expiry
                const canDownloadLatestMatchingDownload = latestMatchingDownload?.status === 'generated' && latestMatchingDownload?.downloadUrl && isLessThanADayOld(latestMatchingDownload?.processedAt);


                // validations
                const orgValidations = organizationResult.validations || [];
                const orgUploadValidations = orgValidations.filter((v) => v.uploadId === fUpload.uploadId)
                const latestValidation = orgUploadValidations.length > 0 ? orgUploadValidations[orgUploadValidations.length - 1] : null
                latestValidation && console.log(`latestValidation`, JSON.stringify({
                    id: latestValidation._id,
                    status: latestValidation.status,
                }))
                const validationStartDTStr = latestValidation ? new Date(latestValidation.createdAt).toLocaleDateString('en-us', dtStrOpts) : null;
                const validationFinishDTStr = latestValidation && latestValidation.processedAt
                    ? new Date(latestValidation.processedAt).toLocaleDateString('en-us', dtStrOpts) : null;

                return (
                    <div key={fUpload.uploadId}>
                        <Accordion
                            style={{
                                margin: '24px 0',
                            }}
                            expanded={expandAccordion}
                            onChange={(e, expanded) => {
                                setAccordionExpansionMap({
                                    ...accordionExpansionMap,
                                    [fUpload.uploadId]: expanded,
                                })
                            }}
                        >
                            <AccordionSummary sx={{ margin: '4px 0' }} expandIcon={<ExpandMoreIcon />}>
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <div>
                                                <div>
                                                    <Typography variant="body1">
                                                        <span>{fUpload.friendlyName || fUpload.fileName}</span>
                                                        <IconButton
                                                            sx={{ height: '24px', width: '24px', marginTop: '-2px', marginLeft: '2px' }}
                                                            size="small"
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                setEditFileUploadNameContext({
                                                                    organizationId: organization.id,
                                                                    uploadId: fUpload.uploadId,
                                                                    friendlyName: fUpload.friendlyName || fUpload.fileName,
                                                                    setFriendlyName: (ctx, name) => {
                                                                        console.log('setFriendlyName', name, editFileUploadNameContext)
                                                                        setEditFileUploadNameContext({
                                                                            ...ctx,
                                                                            friendlyName: name,
                                                                        })
                                                                    },
                                                                })
                                                                setOpenEditFileUploadNameDialog(true)
                                                            }}
                                                        >
                                                            <EditIcon fontSize="inherit" />
                                                        </IconButton>
                                                    </Typography>
                                                </div>
                                                <div>
                                                    {fUpload.friendlyName && (
                                                        <div>
                                                            <Typography variant="body1">{fUpload.fileName}</Typography>
                                                        </div>
                                                    )}
                                                    <div>
                                                        <Typography variant="body1" color='gray'>
                                                            {importStartDTStr}{importFinishDTStr && (
                                                                <>
                                                                    {' - '}
                                                                    {importFinishDTStr}
                                                                </>
                                                            )}
                                                        </Typography>
                                                    </div>
                                                    {validationStartDTStr && (<div>
                                                        <Typography variant="body1" color='gray'>
                                                            {validationStartDTStr}{validationFinishDTStr && (
                                                                <>
                                                                    {' - '}
                                                                    {validationFinishDTStr}
                                                                </>
                                                            )}
                                                        </Typography>
                                                    </div>)}
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <div style={{ marginTop: typeof fUpload.friendlyName === 'string' && fUpload.friendlyName.trim() !== '' ? '48px' : '24px' }}></div>
                                            <div>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={9}>
                                                        <LinearProgress
                                                            sx={{
                                                                display: 'inline-block',
                                                                width: '100%',
                                                                ...(fUpload.status === 'imported' || fUpload.status === 'failed' || fUpload.status === 'partial_import' ? {
                                                                    backgroundColor: fileUploadStatusColor(fUpload.status),
                                                                    '& .MuiLinearProgress-bar': {
                                                                        backgroundColor: fileUploadStatusColor(fUpload.status)
                                                                    }
                                                                } : undefined)
                                                            }}
                                                            variant={fUpload.status === 'importing' ? 'indeterminate' : 'determinate'}
                                                            value={fUpload.status === 'failed' ? 100 : uploadProgress}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Typography variant="subtitle2" color={fileUploadStatusColor(fUpload.status)}>
                                                            {fileUploadStatusDisplayName(fUpload.status)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            {latestValidation && <div>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={9}>
                                                        <LinearProgress
                                                            sx={{
                                                                display: 'inline-block',
                                                                width: '100%',
                                                                ...(latestValidation.status !== 'validating' ? {
                                                                    backgroundColor: validationStatusColor(latestValidation.status),
                                                                    '& .MuiLinearProgress-bar': {
                                                                        backgroundColor: validationStatusColor(latestValidation.status)
                                                                    }
                                                                } : undefined)
                                                            }}
                                                            variant={latestValidation.status === 'validating' ? 'indeterminate' : 'determinate'}
                                                            value={latestValidation.status === 'validating' ? 50 : 100}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Typography variant="subtitle2" color={validationStatusColor(latestValidation.status)}>
                                                            {validationStatusDisplayName(latestValidation.status)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </div>}
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Grid container>
                                                {/*
                                                    The <spans> are necessary for the tooltip to show when buttons are disabled.
                                                    https://mui.com/material-ui/react-tooltip/#disabled-elements
                                                */}
                                                <Grid item>
                                                    <Tooltip title="Model">
                                                        <span>
                                                            <Link
                                                                style={disableLinkStyles}
                                                                to={`/models/${fUpload.uploadId}?organizationId=${organization.id}`}
                                                                component={IconButton}
                                                                onClick={(e) => e.stopPropagation()}
                                                            >
                                                                <TuneIcon />
                                                            </Link>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip title="Validate">
                                                        <span>
                                                            <IconButton disabled={fUpload.status !== 'imported' || latestValidation?.status === 'validating'}
                                                                // style={}
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    console.log(`Kick off validations for ${fUpload.uploadId}`)

                                                                    // Note: Until we get further, we're going to provide the layerName at the server

                                                                    mutStartValidations({ organizationId: organization.id, uploadId: fUpload.uploadId })

                                                                    // expand accordion to eventually show layer progress
                                                                    // setAccordionExpansionMap({
                                                                    //     ...accordionExpansionMap,
                                                                    //     [fUpload.uploadId]: true,
                                                                    // })
                                                                }}>
                                                                {isStartingValidations ? <CircularProgress size="24px" /> : <FactCheckIcon />}
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip title="Grid">
                                                        <span>
                                                            <Link
                                                                style={disableLinkStyles}
                                                                to={`/layerTable/${fUpload.uploadId}?organizationId=${organizationResult.id}`}
                                                                component={IconButton}
                                                                onClick={(e) => e.stopPropagation()}
                                                            >
                                                                <TableChartIcon />
                                                            </Link>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip title="Map">
                                                        <span>
                                                            <Link
                                                                style={disableLinkStyles}
                                                                to={`/map/${fUpload.uploadId}?organizationId=${organizationResult.id}`}
                                                                component={IconButton}
                                                                onClick={(e) => e.stopPropagation()}
                                                            >
                                                                <MapIcon />
                                                            </Link>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip title="Download">
                                                        <span>
                                                            <IconButton disabled={isDownloading || (isDownloadingLatestMatchingDownload && !canDownloadLatestMatchingDownload) || (fUpload.status === 'partial_import' && allFilesFinished(fUpload)) || fUpload.status !== 'imported'}
                                                                style={canDownloadLatestMatchingDownload ? { color: blue[500] } : undefined}
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    console.log(`Download ${fUpload.uploadId}`)

                                                                    if (canDownloadLatestMatchingDownload) {
                                                                        console.log('DOWNLOAD THE FILE')
                                                                        window.open(latestMatchingDownload?.downloadUrl, '_blank')
                                                                    } else {
                                                                        console.log('GENERATE THE DOWNLOAD')
                                                                        downloadGDBMutate({ organizationId: organization.id, uploadId: fUpload.uploadId })
                                                                    }
                                                                }}>
                                                                {isDownloading || (isDownloadingLatestMatchingDownload && !canDownloadLatestMatchingDownload) ? <CircularProgress size="24px" /> : <DownloadIcon />}
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip title="Delete">
                                                        <span>
                                                            <IconButton
                                                                disabled={organization?.auth0Name === 'snocom911'
                                                                    || isDeleting
                                                                    || ['queued', 'importing'].indexOf(fUpload.status) > -1
                                                                    || (fUpload.status === 'partial_import' && allFilesFinished(fUpload))
                                                                }
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    setDeleteContext({
                                                                        organizationId: organization.id,
                                                                        uploadId: fUpload.uploadId,
                                                                        // TODO: Replace with file name if it exists
                                                                        fileName: `#${fUpload.uploadNumber}`,
                                                                    })
                                                                    setOpenDeleteDialog(true)
                                                                }}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            </AccordionSummary>
                            <AccordionDetails>
                                {fUpload.layers.map((layer, layerIdx) => {
                                    return (
                                        // Note: -24px is to account for the expand/collapse button of the accordion
                                        <div key={layer.name} style={{ width: 'calc(100% - 24px)' }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={4}>
                                                    <div>
                                                        <div>
                                                            <Typography variant="body1" fontWeight={'bold'}>
                                                                {layer.name}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={9}>
                                                            <LinearProgress
                                                                sx={{
                                                                    display: 'inline-block',
                                                                    width: '100%',
                                                                    ...(layer.status === 'imported' || layer.status === 'failed' ? {
                                                                        backgroundColor: layerUploadStatusColor(layer.status),
                                                                        '& .MuiLinearProgress-bar': {
                                                                            backgroundColor: layerUploadStatusColor(layer.status)
                                                                        }
                                                                    } : undefined)
                                                                }}
                                                                variant={
                                                                    fUpload.status === 'failed' || layer.status === 'failed'
                                                                        ? 'determinate'
                                                                        : layer.expectedFeatureCount === layer.insertedFeatureCount
                                                                            ? 'determinate'
                                                                            : 'indeterminate'
                                                                }
                                                                value={
                                                                    fUpload.status === 'failed'
                                                                        ? 100
                                                                        : layer.expectedFeatureCount === layer.insertedFeatureCount
                                                                            ? 100
                                                                            : layer.expectedFeatureCount > 0
                                                                                ? ((layer.insertedFeatureCount ?? 0) / layer.expectedFeatureCount) * 100
                                                                                : 0
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography
                                                                variant="subtitle2"
                                                                color={layerUploadStatusColor(layer.status)}
                                                                sx={{ display: 'inline-block' }}
                                                            >
                                                                {layerUploadStatusDisplayName(layer.status)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        <Typography variant="body1" fontWeight={'bold'}>
                                                            {layer.insertedFeatureCount?.toLocaleString()} / {layer.expectedFeatureCount?.toLocaleString()}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            {layerIdx + 1 < fUpload.layers.length && (
                                                <Divider
                                                    style={{
                                                        margin: '12px 0',
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )
                                })}
                            </AccordionDetails>
                        </Accordion>
                    </div >
                )
            })}

            {/* EDIT FILE UPLOAD NAME DIALOG */}
            <Dialog
                sx={{
                    '& .MuiDialog-paper': {
                        minWidth: '400px',
                    },
                }}
                open={openEditFileUploadNameDialog}
                onClose={() => {
                    if (isUpdatingFileUploadName) {
                        return
                    }
                    closeEditFileUploadNameDialog()
                }}
            >
                <DialogTitle>Update Upload</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <TextField
                            fullWidth
                            label="Upload Name"
                            variant="standard"
                            value={editFileUploadNameContext?.friendlyName}
                            onChange={(e) => editFileUploadNameContext?.setFriendlyName(editFileUploadNameContext, e.target.value)}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeEditFileUploadNameDialog} disabled={isUpdatingFileUploadName}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            if (!editFileUploadNameContext) {
                                throw Error('editFileUploadNameContext is null, unable to proceed.')
                            }
                            updateFileUploadName({
                                organizationId: editFileUploadNameContext.organizationId,
                                uploadId: editFileUploadNameContext.uploadId,
                                name: editFileUploadNameContext.friendlyName,
                            })
                        }}
                        color="primary"
                        autoFocus
                        disabled={isUpdatingFileUploadName}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/* DELETE DIALOG */}
            <Dialog
                open={openDeleteDialog}
                onClose={() => {
                    if (isDeleting) {
                        return
                    }
                    closeDeleteDialog()
                }}
            >
                <DialogTitle>{`Delete Upload ${deleteContext?.fileName ?? ''}`.trim()}</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete this upload? This action cannot be undone.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} disabled={isDeleting}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            if (!deleteContext) {
                                throw Error('deleteContext is null, unable to proceed.')
                            }
                            deleteOrgFileUploadMutate(deleteContext)
                        }}
                        color="error"
                        autoFocus
                        disabled={isDeleting}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/* DELETE SUCCESS SNACKBAR */}
            <Snackbar open={showDeleteSuccess} autoHideDuration={6000} onClose={handleDeleteSuccessSnackbarClose}>
                <Alert onClose={handleDeleteSuccessSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Upload deleted
                </Alert>
            </Snackbar>
        </>
    )
}

export default OrganizationFileUploads
