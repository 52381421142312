import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import { TFileUpload } from '../hooks/useOrganization';
import { getFeatureById, getFeatureByLayerName, getFeaturePropertiesByLayerName, getFeatureValidationsByLayerName, TFeature } from '../api/featureController';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useAppContext } from '../App';
import { Link } from 'react-router-dom';
import MapIcon from '@mui/icons-material/Map'
import { TUploadedFeature } from '../api/editController';
import { TValidation } from '../api/validationsController';
import { ValidationMapGrid } from './ValidationMapGrid';



const FeatureTable: React.FC<{ currentOrganizationId: string, file: TFileUpload }> = ({ currentOrganizationId, file }) => {
    const pageSize = 50;
    const tableContainerRef = useRef<HTMLDivElement>(null)
    const { accessToken } = useAppContext()
    const [layer, setLayer] = useState(file.layers[0])
    const [searchFields, setSearchFields] = useState<string[]>([])
    const [inputValue, setInputValue] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [validationRuleFilter, setValidationRuleFilter] = useState<string>()
    const [maxCount, setMaxCount] = useState<number>(0)

    const [selectedRule, setSelectedRule] = useState<string | undefined>()
    const [selectedFeatureRowId, setSelectedFeatureRowId] = useState<string | undefined>()
    const [isFetchingDynamicValidationRows, setIsFetchingDynamicValidationRows] = useState<boolean>(false)
    const [dynamicValidationRows, setDynamicValidationRows] = useState<TUploadedFeature[]>()

    const fileUploadId = file.uploadId
    let resultsFetched = 0
    var lastScrollLeft = 0;

    const { data: featureKeysResponse, isLoading: arePropertiesLoading } = useQuery(['get-feature-properties-by-layername', layer.name], () => {
        if (!accessToken || !currentOrganizationId) {
            return
        }
        return getFeaturePropertiesByLayerName(accessToken, layer.name, currentOrganizationId, fileUploadId)
    })

    const { data: featureValidationsResponse, isLoading: isLoadingFeatureValidations } = useQuery(['get-feature-validations-by-layername', layer.name], () => {
        if (!accessToken || !currentOrganizationId) {
            return
        }
        return getFeatureValidationsByLayerName(accessToken, layer.name, currentOrganizationId, fileUploadId)
    })

    const { data: featuresResponse, fetchNextPage: getFeatures, isFetching, isLoading, status } = useInfiniteQuery(
        ['get-features', layer.name, searchFields, searchTerm, validationRuleFilter],
        ({ pageParam = 1 }) => {
            var data = getFeatureByLayerName(accessToken!, layer.name, currentOrganizationId, fileUploadId, pageParam, pageSize, searchFields, searchTerm, validationRuleFilter)
            return data
        },
        {
            getNextPageParam: (lastPage, allPages) => {
                resultsFetched = ((allPages.length - 1) * pageSize) + lastPage.data.features.length
                if (resultsFetched < maxCount) {
                    return allPages.length + 1;
                }
                return undefined;
            }
        }
    );

    useEffect(() => {
        const firstPage = featuresResponse?.pages?.length ? featuresResponse.pages[0] : undefined
        if (!firstPage) {
            console.log('No first page')
            return
        }
        setMaxCount(firstPage.data.maxCount)
    }, [featuresResponse]);

    useEffect(() => {

        var tableRef = tableContainerRef.current
        tableRef?.addEventListener('scroll', scrollListener)

        return () => {
            tableRef?.removeEventListener('scroll', scrollListener)
        }
    })

    function debounce(func: (...args: any[]) => void, wait: number) {
        let timeout: NodeJS.Timeout;
        return function executedFunction(...args: any[]) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    };

    const scrollListener = debounce(() => {
        const container = tableContainerRef.current;
        if (container) {
            const { scrollTop, clientHeight, scrollHeight, scrollLeft } = container;

            if (scrollLeft !== lastScrollLeft) {
                lastScrollLeft = scrollLeft
                return;
            }

            const isScrollAtBottom = (scrollTop + clientHeight >= (scrollHeight * 0.70));

            if (isScrollAtBottom && resultsFetched < maxCount) {
                getFeatures()
            }
        }

    }, 200)

    useEffect(() => {
        if (featureKeysResponse) {
            setSearchFields(featureKeysResponse.data.properties)
        }
    }, [featureKeysResponse]);

    const handleFetching = () => {
        if (isFetching || isLoading) {

            if (status === 'error') {
                //handle errors
            }

            return ((
                <CircularProgress size={24} />

            ))
        }
        return <></>

    }

    const handleLayerChange = (event: SelectChangeEvent<string>) => {
        const newValue = event.target.value as string

        var layer = file.layers.find(layer => layer.name === newValue)
        setSearchTerm('')
        setInputValue('')
        setLayer(layer!)
    }

    const handleValidationRuleFilterChange = (event: SelectChangeEvent<string>) => {
        const newValue = event.target.value as string
        setValidationRuleFilter(newValue)
    }

    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (event && event.target) {
            setSearchTerm(event.target.value)
        }
    }

    const handleKeyboardEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event && event.target && event.key === 'Enter') {
            setSearchTerm(inputValue)
        }
    }

    const handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleValidationRuleClick = async (_e: React.MouseEvent<HTMLButtonElement, MouseEvent>, feature: TFeature, validation: TValidation) => {
        setSelectedFeatureRowId(feature.featureId)
        setSelectedRule(validation.rule)
        setIsFetchingDynamicValidationRows(true)
        try {
            // e.preventDefault()
            console.log(`Feature: ${JSON.stringify(feature, null, 2)}`)
            const featureIdsToFetch: string[] = [feature.featureId]
            validation.relatedFeatureIds.forEach((id) => {
                featureIdsToFetch.push(id)
            })
            const fetchRequests = featureIdsToFetch.map((id) => getFeatureById(accessToken!, currentOrganizationId, fileUploadId, id))
            const featureResponses = await Promise.all(fetchRequests)

            const uploadedFeatures: TUploadedFeature[] = featureResponses.map((response) => {
                return response.data
            });
            setDynamicValidationRows(uploadedFeatures)
        }
        catch (error) {
            console.error(error)
        }
        finally {
            setIsFetchingDynamicValidationRows(false)
        }
    }


    const modalBoxStyles = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: 'calc(100% - 6rem)',
        width: 'calc(100% - 2rem)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        padding: '0 1rem 1rem 1rem',
    };
    return (
        <>
            <Grid container spacing={2} marginTop={'40px'}>
                <Grid item xs={3} alignContent={'center'} justifyContent={'center'}>
                    <Box display="flex"
                        height={'100%'}
                        width={'100%'}>
                        <FormControl sx={{ m: 1, minWidth: 240 }}>
                            <InputLabel id="fuln-input-label">Layer Name</InputLabel>
                            <Select
                                labelId='fuln-input-label'
                                label="Layer Name"
                                value={layer.name} onChange={handleLayerChange}>
                                {file.layers.map((layer, index) => (
                                    <MenuItem key={index} value={layer.name}>{layer.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>

                <Grid item xs={4}>
                    <Box display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        height={'100%'}
                        width={'100%'}>
                        <TextField
                            variant="outlined"
                            placeholder="search"
                            value={inputValue}
                            fullWidth
                            onBlur={handleOnBlur}
                            onKeyDown={handleKeyboardEvent}
                            onChange={handleChangeEvent}
                        />
                    </Box>
                </Grid>

                <Grid item xs={3}>
                    <Box display="flex"
                        height={'100%'}
                        width={'100%'}>
                        <FormControl sx={{ m: 1, minWidth: 240 }}>
                            <InputLabel id="validation-filter-label">Validation Rule</InputLabel>
                            <Select
                                disabled={isLoadingFeatureValidations}
                                labelId='validation-filter-label'
                                label="Validation Rule"
                                value={validationRuleFilter}
                                onChange={handleValidationRuleFilterChange}>
                                <MenuItem key={'-2'} value={''} sx={{ minHeight: '12px' }}>
                                    ---
                                </MenuItem>
                                <MenuItem key={'-1'} value={'any'} sx={{ minHeight: '12px' }}>
                                    Any
                                </MenuItem>
                                {featureValidationsResponse && featureValidationsResponse.data && featureValidationsResponse.data.validationRules.map((vr, idx) => (
                                    <MenuItem key={`${idx}`} value={vr}>{vr}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>

                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                    <p>{resultsFetched.toLocaleString()} out of {maxCount.toLocaleString()} </p>
                </Grid>

                <Grid item xs={12} fontSize={10}>
                    <TableContainer style={{ maxHeight: '70vh' }} ref={tableContainerRef}>
                        {arePropertiesLoading &&
                            <div style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
                                <CircularProgress size={24} />
                            </div>}
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell size='small' align='center' style={{
                                        position: 'sticky',
                                        top: 0,
                                        left: 0,
                                        zIndex: 2,
                                        backgroundColor: 'white'
                                    }}>Map</TableCell>
                                    <TableCell size='small' align='center' style={{
                                        position: 'sticky',
                                        top: 0,
                                        left: 0,
                                        zIndex: 2,
                                        backgroundColor: 'white'
                                    }}>Validations</TableCell>

                                    {featureKeysResponse?.data.properties.map((item, index) => (
                                        <TableCell style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white' }}
                                            size='small' align='center' key={index}>{item}</TableCell>
                                    ))}

                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ cursor: isFetchingDynamicValidationRows ? 'wait' : 'inherit' }}>
                                {featuresResponse?.pages.map(page => (
                                    page.data.features.map((feature, index) => {
                                        const rowIsSelected = typeof selectedFeatureRowId === 'string' ? selectedFeatureRowId === feature.featureId : false
                                        return (
                                            <TableRow key={index} selected={rowIsSelected}>
                                                <TableCell size='small' align='center' style={{
                                                    position: 'sticky',
                                                    left: 0,
                                                    zIndex: 1,
                                                    ...(!rowIsSelected ? { backgroundColor: 'white' } : undefined)
                                                }}>
                                                    <Link
                                                        to={`/map/${file.uploadId}?organizationId=${currentOrganizationId}&layerName=${layer.name}&featureId=${feature.featureId}`}
                                                        component={IconButton}
                                                        onClick={(e) => e.stopPropagation()}
                                                    >
                                                        <MapIcon />

                                                    </Link>
                                                </TableCell>
                                                <TableCell size='small' align='center'>
                                                    {Array.isArray(feature.validations) ? feature.validations.map((validation, index) => {
                                                        return (<div key={validation.id}>
                                                            <Button
                                                                disabled={isFetchingDynamicValidationRows}
                                                                onClick={(e) => handleValidationRuleClick(e, feature, validation)}>{validation.rule}</Button>
                                                        </div>)
                                                    }) : undefined}
                                                </TableCell>
                                                {feature.properties.map((property, propIndex) => (
                                                    <TableCell size='small' align='center'
                                                        key={propIndex}>{property.value}</TableCell>
                                                ))}
                                            </TableRow>
                                        );
                                    }
                                    )))}
                                <TableRow>
                                    <TableCell size='small' align='center'
                                        style={{ position: 'sticky', left: 0, zIndex: 1, backgroundColor: 'white' }}>
                                        {handleFetching()}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            <Modal
                open={dynamicValidationRows !== undefined}
                onClose={() => {
                    console.log('onClose')
                    setDynamicValidationRows(undefined)
                }}
            >
                <Box sx={modalBoxStyles}>
                    {selectedRule && <Typography variant={'subtitle2'}>{selectedRule}</Typography>}
                    {dynamicValidationRows && <ValidationMapGrid features={dynamicValidationRows} />}
                </Box>
            </Modal>
        </>
    );
}

export default FeatureTable;
