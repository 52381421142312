import axios from "axios";


export const nameFileUpload = async (accessToken: string, organizationId: string, fileUploadId: string, name: string) => {
  return axios.put<unknown>(
    `${process.env.REACT_APP_API_SERVER_URL}/v1/files/${fileUploadId}/name`,
    {
      friendlyName: name,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        organizationId
      }
    },
  )
}

export const createOrgFileDownload = async (accessToken: string, organizationId: string, fileUploadId: string) => {
    return axios.post<{downloadId: string, status: string}>(
        `${process.env.REACT_APP_API_SERVER_URL}/v1/files/${fileUploadId}/download`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
              organizationId
          }
        },
      )
}

export const deleteOrgFileUpload = async (accessToken: string, organizationId: string, fileUploadId: string) => {
  return axios.delete<unknown>(
    `${process.env.REACT_APP_API_SERVER_URL}/v1/files/delete`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        organizationId,
        fileUploadId
      }
    },
  )
}
