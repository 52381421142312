import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip } from "@mui/material";
import React from "react";
import { type Feature } from "geojson";
import { sortProperties } from './UpdateFeatureTab'

type FeatureInfoTabProps = { selectedFeature?: { layerName: string, feature: Feature }, propsByLayerNameObj: any }


const renderFeatureValue = (value: any) => {

    if (value) {
        return (
            <Tooltip title={value}>
                <TableCell align="left">{value}</TableCell>
            </Tooltip>
        )
    }

    return (
        <TableCell align="left">{value}</TableCell>
    )
}

export const FeatureInfoTab: React.FC<FeatureInfoTabProps> = ({ selectedFeature, propsByLayerNameObj }) => {
    if (!selectedFeature || !selectedFeature.feature.properties) return (<></>)
    const properties = Object.entries(selectedFeature.feature.properties).map(([key, value], i) => ({ name: key, value: value }))

    const sortedProperties = sortProperties(propsByLayerNameObj, selectedFeature.layerName, properties)
    return (
        <React.Fragment>
            <TableContainer >
                <Table size={'small'}>
                    <TableHead>
                        <TableRow style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white' }}>
                            <TableCell>Name</TableCell>
                            <TableCell align="left">Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedProperties
                            .map((featurePropKey) => {
                                return (
                                    <TableRow key={featurePropKey.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            {featurePropKey.name}
                                        </TableCell>
                                        {renderFeatureValue(featurePropKey.value)}
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}
