import { useCallback, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Map, Layer, MapRef, Source } from 'react-map-gl'
import { Box, CircularProgress } from '@mui/material'
import axios from 'axios'
import mapboxgl from 'mapbox-gl'

import { useRequest } from '../../hooks/useRequest'

type PublicLayer = {
    name: string
    type: string
    color: string | null
    insertedFeatureCount: number | null
    extent: {
        xMin: number
        yMin: number
        xMax: number
        yMax: number
    }
}

type PublicMap = {
    uploadId: string
    collectionName: string
    friendlyName: string | null
    insertedFeatureCount: number | null
    layers: PublicLayer[]
}

const getPublicMap = async (fileUploadId: string) => {
    const response = await axios.get<PublicMap>(`${process.env.REACT_APP_API_SERVER_URL}/v1/public/map/${fileUploadId}`)
    return response.data
}

const FillLayer = ({
    layer,
}: {
    layer: PublicLayer
}) => (
    <>
        <Layer
            source={layer.name}
            source-layer={layer.name}
            type="fill"
            layout={{ visibility: 'none' }}
        />
    </>
)

const LineLayer = ({
    layer,
}: {
    layer: PublicLayer
}) => (
    <>
        <Layer
            source={layer.name}
            source-layer={layer.name}
            type="line"
            paint={{
                'line-color': 'black',
                'line-width': [
                    'interpolate',
                    ['exponential', 2],
                    ['zoom'],
                    10, ["*", 10, ["^", 2, -6]],
                    24, ["*", 10, ["^", 2, 8]]
                ],
            }}
            layout={{
                'line-cap': 'round',
            }}
        />
        <Layer
            source={layer.name}
            source-layer={layer.name}
            type="line"
            paint={{
                'line-color': 'white',
                'line-width': [
                    'interpolate',
                    ['exponential', 2],
                    ['zoom'],
                    10, ["*", 8, ["^", 2, -6]],
                    24, ["*", 8, ["^", 2, 8]]
                ],
            }}
            layout={{
                'line-cap': 'round',
            }}
        />
        <Layer
            source={layer.name}
            source-layer={layer.name}
            type="symbol"
            paint={{
                'text-halo-color': 'white',
                'text-halo-width': 2,
            }}
            layout={{
                'text-field': ['format', ['get', 'R_F_ADD'], '-', ['get', 'R_T_ADD']],
                'symbol-placement': 'line-center',
                'text-offset': [0, 2],
                'text-size': 12,
            }}
        />
        <Layer
            source={layer.name}
            source-layer={layer.name}
            type="symbol"
            paint={{
                'text-halo-color': 'white',
                'text-halo-width': 2,
            }}
            layout={{
                'text-field': ['format', ['get', 'L_F_ADD'], '-', ['get', 'L_T_ADD']],
                'symbol-placement': 'line-center',
                'text-offset': [0, -2],
                'text-size': 12,
            }}
        />
        <Layer
            source={layer.name}
            source-layer={layer.name}
            type="symbol"
            paint={{
                'text-halo-color': 'white',
                'text-halo-width': 2,
            }}
            layout={{
                'text-field': ['format', ['get', 'PRD'], ' ', ['get', 'STP'], ' ', ['get', 'RD'], ' ', ['get', 'STS']],
                'symbol-placement': 'line-center',
                'text-offset': [0, 0],
                'text-size': 14,
            }}
        />
    </>
)

const CircleLayer = ({
    layer,
}: {
    layer: PublicLayer
}) => (
    <>
        <Layer
            source={layer.name}
            source-layer={layer.name}
            type="circle"
            layout={{ visibility: 'none' }}
        />
    </>
)

const SourceAndLayers = ({
    layer,
    url,
}: {
    layer: PublicLayer
    url: string
}) => {
    return (
        <Source
            id={layer.name}
            type="vector"
            tiles={[url]}
            promoteId="_id"
        >
            {(layer.type === 'Polygon' || layer.type === 'Multi Polygon') && <FillLayer layer={layer} />}
            {(layer.type === 'Line String' || layer.type === 'Multi Line String') && <LineLayer layer={layer} />}
            {(layer.type === 'Point' || layer.type === 'Multi Point') && <CircleLayer layer={layer} />}
        </Source >
    )
}

export const MapPage = () => {
    const { fileUploadId } = useParams<{ fileUploadId: string }>()
    const getPublicMapCallback = useCallback(async () => await getPublicMap(fileUploadId), [fileUploadId])
    const {
        data: publicMap,
        loading: publicMapLoading,
        error: publicMapError,
    } = useRequest(getPublicMapCallback)
    console.log(`publicMapError: ${publicMapError}`)
    const mapRef = useRef<MapRef>(null)
    useEffect(() => {
        const layerWithMostFeatures = publicMap?.layers.reduce((previousValue, currentValue) => previousValue?.insertedFeatureCount && currentValue?.insertedFeatureCount && currentValue.insertedFeatureCount > previousValue.insertedFeatureCount ? currentValue : previousValue)
        layerWithMostFeatures && mapRef.current?.fitBounds(
            new mapboxgl.LngLatBounds(
                new mapboxgl.LngLat(layerWithMostFeatures.extent.xMin, layerWithMostFeatures.extent.yMin),
                new mapboxgl.LngLat(layerWithMostFeatures.extent.xMax, layerWithMostFeatures.extent.yMax),
            )
        )
    }, [publicMap])
    const layerUrl = (collectionName: string, layerName: string) => `https://mongeo.azurewebsites.net/tiles/${collectionName}/layer/${layerName}/{z}/{x}/{y}`
    return (
        <Box sx={{}}>
            <Map
                ref={mapRef}
                style={{ height: '100vh', width: '100vw' }}
                mapStyle="mapbox://styles/matt-dickinson-911geo-com/cm3fcddke001d01so4g80fsyo"
            >
                {publicMap?.layers.map((layer) => (
                    <SourceAndLayers
                        key={layer.name}
                        layer={layer}
                        url={layerUrl(publicMap.collectionName, layer.name)}
                    />
                ))}
            </Map >
            <CircularProgress sx={{ display: publicMapLoading ? 'block' : 'none', position: 'absolute', top: 10, left: 10 }} />
        </Box>
    )
}
